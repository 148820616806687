html,
body {
  margin: 0;
}

html,
body,
#root {
  height: 100%;
}

#root {
  display: flex
}